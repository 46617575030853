@import "../styles/variable";

.title-dialog-color {
  color: $color-teal-navy-blue;
}

.h-calc-vh-150px {
  height: calc(100vh - 150px);
}

.left-60 {
  left: 60% !important;
}

.status {
  width: 45%;
}

.tab-xet-nghiem {
  .table-xet-nghiem {
    height: calc(100% - 220px) !important;
  }
}

.table-lay-mau-benh-pham {
  height: calc(100% - 181px) !important;

  .border-right {
    border-right: 2px solid $color-dark-blur-2;
  }
}

.grid-8-4 {
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: 2px;
  margin-bottom: 2px;
}

.grid-2_4-9_6 {
  display: grid !important;
  grid-template-columns: 2.4fr  9.6fr;
}

.grid-1-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 10px;
}

.grid-6-6 {
  display: grid;
  grid-template-columns: 6fr 6fr;
  gap: 2px;
  margin-bottom: 2px;
}

.grid-7-5 {
  display: grid;
  grid-template-columns: 7fr 5fr;
  gap: 2px;
  margin-bottom: 2px;
}

.w-288 {
  width: 288px;
}

.w-428 {
  width: 428px;
}

.color-disable {
  color: $bg-button-disabled;
}

.text-info {
  color: #1a5e83 !important;
}

.search-icon {
  text-align: center;
  margin: 0 !important;
  width: 29px;
  top: 2px;
  right: 0px;
  padding-top: 8px;
  padding-right: 1px;
  height: 28px;
  border-left: 1px solid #a9a9a9 !important;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid $color-teal-navy-blue;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  animation: spin 1s linear infinite;
  position: relative;
  left: 4px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .status {
    width: 90% !important;
  }
}

.count-status-xet-nghiem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}

.min-w-26-percent {
  min-width: 26% !important;
}

.h-31 {
  height: 31px;
}

.hiNormal {
  color: #dc3545;
  font-weight: bold;
}

.lowNormal {
  color: #17a2b8;
  font-weight: bold;
}