.spaces {
  $spaces: (
    0: 0px,
    1: 1px,
    2: 2px,
    3: 3px,
    4: 4px,
    4_5: 4.5px,
    5: 5px,
    6: 6px,
    7: 7px,
    8: 8px,
    9: 9px,
    10: 10px,
    12: 12px,
    13: 13px,
    14: 14px,
    15: 15px,
    16: 16px,
    17: 17px,
    20: 20px,
    21: 21px,
    22: 22px,
    23: 23px,
    24: 24px,
    25: 25px,
    26: 26px,
    27: 27px,
    28: 28px,
    29: 29px,
    30: 30px,
    32: 32px,
    34: 34px,
    35: 35px,
    36: 36px,
    38: 38px,
    40: 40px,
    42: 42px,
    44: 44px,
    45: 45px,
    47: 47px,
    48: 48px,
    49: 49px,
    50: 50px,
    53: 53px,
    54: 54px,
    56: 56px,
    60: 60px,
    58: 58px,
    62: 62px,
    65: 65px,
    66: 66px,
    70: 70px,
    71: 71px,
    72: 72px,
    75: 75px,
    76: 76px,
    80: 80px,
    81: 81px,
    85: 85px,
    86: 86px,
    88: 88px,
    89: 89px,
    90: 90px,
    92: 92px,
    95: 95px,
    96: 96px,
    94: 94px,
    100: 100px,
    102: 102px,
    103: 103px,
    105: 105px,
    108: 108px,
    110: 110px,
    115: 115px,
    116: 116px,
    117: 117px,
    120: 120px,
    121: 121px,
    123: 123px,
    124: 124px,
    125: 125px,
    126: 126px,
    127: 127px,
    128: 128px,
    129: 129px,
    130: 130px,
    132: 132px,
    133: 133px,
    134: 134px,
    135: 135px,
    140: 140px,
    144: 144px,
    145: 145px,
    149: 149px,
    150: 150px,
    152: 152px,
    154: 154px,
    155: 155px,
    160: 160px,
    161: 161px,
    163: 163px,
    165: 165px,
    166: 166px,
    167: 167px,
    169: 169px,
    170: 170px,
    172: 172px,
    175: 175px,
    176: 176px,
    177: 177px,
    180: 180px,
    185: 185px,
    186: 186px,
    188: 188px,
    190: 190px,
    197: 197px,
    208: 208px,
    200: 200px,
    201: 201px,
    204: 204px,
    210: 210px,
    214: 214px,
    217: 217px,
    227: 227px,
    240: 240px,
    220: 220px,
    228: 228px,
    230: 230px,
    239: 239px,
    243: 243px,
    245: 245px,
    246: 246px,
    250: 250px,
    255: 255px,
    256: 256px,
    259: 259px,
    261: 261px,
    263: 263px,
    265: 265px,
    273: 273px,
    276: 276px,
    280: 280px,
    285: 285px,
    290: 290px,
    291: 291px,
    294: 294px,
    296: 296px,
    297: 297px,
    299: 299px,
    300: 300px,
    310: 310px,
    313: 313px,
    315: 315px,
    319: 319px,
    324: 324px,
    326: 326px,
    329: 329px,
    333: 333px,
    335: 335px,
    340: 340px,
    341: 341px,
    345: 345px,
    347: 347px,
    351: 351px,
    353: 353px,
    354: 354px,
    357: 357px,
    358: 358px,
    360: 360px,
    343: 343px,
    364: 364px,
    370: 370px,
    378: 378px,
    380: 380px,
    400: 400px,
    404: 404px,
    425: 425px,
    430: 430px,
    442: 442px,
    447: 447px,
    450: 450px,
    453: 453px,
    455: 455px,
    459: 459px,
    465: 465px,
    470: 470px,
    471: 471px,
    480: 480px,
    484: 484px,
    485: 485px,
    489: 489px,
    493: 493px,
    503: 503px,
    510: 510px,
    511: 511px,
    515: 515px,
    525: 525px,
    532: 532px,
    540: 540px,
    545: 545px,
    549: 549px,
    552: 552px,
    560: 560px,
    566: 566px,
    578: 578px,
    588: 588px,
    580: 580px,
    599: 599px,
    620: 620px,
    680: 680px,
    710: 710px,
    805: 805px,
    912: 912px,
    1328: 1328px,
    1388: 1388px,
    1500: 1500px,
    2000: 2000px,
    2500: 2500px,
    3000: 3000px,
    4000: 4000px,
    5000: 5000px,
    6000: 6000px,
    10000: 10000px
  );

@each $name,
$value in $spaces {
  &.m-#{$name} {
    margin: $value !important;
  }

  &.mt-#{$name} {
    margin-top: $value !important;
  }

  &.mr-#{$name} {
    margin-right: $value !important;
  }

  &.mb-#{$name} {
    margin-bottom: $value !important;
  }

  &.ml-#{$name} {
    margin-left: $value !important;
  }

  &.mx-#{$name} {
    margin-left: $value !important;
    margin-right: $value !important;
  }

  &.my-#{$name} {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }

  &.p-#{$name} {
    padding: $value !important;
  }

  &.pt-#{$name} {
    padding-top: $value !important;
  }

  &.pr-#{$name} {
    padding-right: $value !important;
  }

  &.pb-#{$name} {
    padding-bottom: $value !important;
  }

  &.pl-#{$name} {
    padding-left: $value !important;
  }

  &.px-#{$name} {
    padding-left: $value !important;
    padding-right: $value !important;
  }

  &.py-#{$name} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }

  &.h-#{$name} {
    height: $value !important;
  }

  &.h-#{$name}vh {
    height: $name+vh !important;
  }

  &.w-#{$name}vw {
    width: $name+vw !important;
  }

  &.W-#{$name} {
    width: $value !important;
  }

  &.min-w-#{$name} {
    min-width: $value !important;
  }

  &.min-w-calc-vw-#{$name} {
    min-width: calc(100vh - $value) !important;
  }

  &.min-w-calc-#{$name} {
    min-width: calc(100% - $value) !important;
  }

  &.min-h-#{$name} {
    min-height: $value !important;
  }

  &.min-h-calc-#{$name} {
    min-height: calc(100vh - $value) !important;
  }

    &.max-h-calc-#{$name} {
      max-height: calc(100vh - $value) !important;
    }

  &.max-w-#{$name} {
    max-width: $value !important;
  }

  &.max-w-calc-#{$name} {
    max-width: calc(100% - $value) !important;
  }

  &.gap-#{$name} {
    gap: $value !important;
  }

  &.bottom-#{$name} {
    bottom: $value !important;
  }

  &.line-height-#{$name} {
    line-height: $value !important;
  }

  &.left-#{$name} {
    left: $value !important;
  }

  &.right-#{$name} {
    right: $value !important;
  }

  &.top-#{$name} {
    top: $value !important;
  }

  &.min-h-#{$name} {
    min-height: $value !important;
  }

  &.fw-#{$name} {
    font-weight: $name * 100 !important;
  }

  &.h-calc-vh-#{$name} {
    height: calc(100vh - $value) !important;
  }

  &.h-calc-#{$name} {
    height: calc(100% - $value) !important;
  }

  &.flex-#{$name} {
    flex: $name !important;
  }

  &.radius-#{$name} {
    border-radius: $value !important;
  }

  &.max-h-#{$name} {
    max-height: $value !important;
  }
}

$spaces_percent: (
  0_6: 0.6%,
  1: 1%,
  2: 2%,
  3: 3%,
  4: 4%,
  5: 5%,
  6: 6%,
  7: 7%,
  8: 8%,
  9: 9%,
  10: 10%,
  11: 11%,
  12: 12%,
  13: 13%,
  14: 14%,
  15: 15%,
  16: 16%,
  17: 17%,
  18: 18%,
  19: 19%,
  20: 20%,
  21: 21%,
  22: 22%,
  23: 23%,
  24: 24%,
  25: 25%,
  26: 26%,
  27: 27%,
  27_4: 27.4%,
  28: 28%,
  29: 29%,
  30: 30%,
  31: 31%,
  32: 32%,
  33: 33%,
  34: 34%,
  35: 35%,
  36: 36%,
  37: 37%,
  38: 38%,
  39: 39%,
  40: 40%,
  41: 41%,
  42: 42%,
  43: 43%,
  44: 44%,
  45: 45%,
  46: 46%,
  47: 47%,
  48: 48%,
  49: 49%,
  50: 50%,
  51: 51%,
  52: 52%,
  53: 53%,
  54: 54%,
  55: 55%,
  56: 56%,
  57: 57%,
  58: 58%,
  59: 59%,
  60: 60%,
  61: 61%,
  62: 62%,
  63: 63%,
  64: 64%,
  65: 65%,
  66: 66%,
  67: 67%,
  68: 68%,
  69: 69%,
  70: 70%,
  71: 71%,
  72: 72%,
  72_6: 72.6%,
  73: 73%,
  74: 74%,
  75: 75%,
  76: 76%,
  77: 77%,
  78: 78%,
  79: 79%,
  80: 80%,
  81: 81%,
  82: 82%,
  83: 83%,
  84: 84%,
  85: 85%,
  86: 86%,
  87: 87%,
  88: 88%,
  89: 89%,
  90: 90%,
  91: 91%,
  92: 92%,
  93: 93%,
  94: 94%,
  95: 95%,
  96: 96%,
  97: 97%,
  98: 98%,
  99: 99%,
  100: 100%,
);

@each $name, $value in $spaces_percent {
  &.width-#{$name} {
    width: $value !important;
  }

  &.height-#{$name} {
    height: $value !important;
  }

  &.scale-#{$name} {
    scale: $value*10 !important;
  }

  &.custom-size-dialog {
    &-#{$name}>.modal-dialog {
      width: $value !important;
      padding: 50px 0 !important;
    }
  }

  &.ml-pt-#{$name} {
    margin-left: $value !important;
  }

  &.up-#{$name} {
    bottom: $value !important;
  }
  
  &.down-#{$name} {
    top: $value !important;
  }
}

;

&.pb-30 {
  @include media(767px) {
    padding-bottom: 16px !important;
  }
}

&.pb-1 {
  padding-bottom: 1px;
}

&.pb-2 {
  padding-bottom: 2px;
}

&.pb-3 {
  padding-bottom: 3px;
}

&.px-80 {
  padding-right: 80px;
  padding-left: 80px;

  @media screen and (max-width: 460px) {
    padding-right: 16px;
    padding-left: 16px;
  }
}

&.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
  // @media screen and (max-width: 460px) {
  //   padding-top: 16px;
  //   padding-bottom: 16px;
  // }
}

&.py-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

&.p-sm-30 {
  padding: 30px;

  @include media(767px) {
    padding: 16px;
  }
}

&.px-sm-30 {
  padding: 0px 30px;

  @include media(767px) {
    padding: 0px 16px;
  }
}

&.pr-sm-30 {
  padding-right: 30px !important;

  @include media(767px) {
    padding-right: 16px !important;
  }
}

&.p-sm-24 {
  padding: 24px !important;

  @include media(767px) {
    padding: 16px !important;
  }
}

&.px-sm-24 {
  padding: 0px 24px !important;

  @include media(767px) {
    padding: 0px 16px !important;
  }
}

&.pt-sm-24 {
  padding-top: 24px !important;

  @include media(767px) {
    padding-top: 16px !important;
  }
}

&.pl-sm-24 {
  padding-left: 24px !important;

  @include media(767px) {
    padding: 12px !important;
  }
}

&.m-auto {
  margin: auto !important;
}

&.mr-auto {
  margin-right: auto !important;
}

&.mt-auto {
  margin-top: auto !important;
}

&.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

&.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

&.mx-pt-12 {
  margin-top: 12%;
  margin-bottom: 12%;
}

&.mb-38 {
  margin-bottom: 38px;
}

&.mb-14 {
  margin-bottom: 14px !important;
}

&.mb-1 {
  margin-bottom: 1px;
}

&.mb-2 {
  margin-bottom: 2px;
}

&.ml--12 {
  margin-left: -12px;
}

&.m-sm-30 {
  margin: 30px;

  @include media(767px) {
    margin: 16px;
  }
}

&.mb-sm-30 {
  margin-bottom: 30px;

  @include media(767px) {
    margin-bottom: 16px;
  }
}

&.mt-sm-30 {
  margin-top: 30px;

  @include media(767px) {
    margin-top: 16px;
  }
}

&.mx-sm-30 {
  margin-right: 30px;
  margin-left: 30px;

  @include media(767px) {
    margin-right: 16px;
    margin-left: 16px;
  }
}

&.w-10 {
  width: 10%;
}

&.w-15 {
  width: 15%;
}

&.w-17 {
  width: 17%;
}

&.w-20 {
  width: 20%;
}

&.w-22 {
  width: 22%;
}

&.w-25 {
  width: 25%;
}

&.w-28 {
  width: 28%;
}

&.w-30 {
  width: 30%;
}

&.w-35 {
  width: 35%;
}

&.w-37 {
  width: 37%;
}

&.w-40 {
  width: 40%;
}

&.w-45 {
  width: 45%;
}

&.w-50 {
  width: 50%;
}

&.w-60 {
  width: 60%;
}

&.w-62 {
  width: 62%;
}

&.w-70 {
  width: 70%;
}

&.w-72 {
  width: 72%;
}

&.w-80 {
  width: 80%;
}

&.w-85 {
  width: 85%;
}

&.w-90 {
  width: 90% !important;
}

&.w-100 {
  width: 100%;
}

&.w-220 {
  width: 220px;
}

&.w-29 {
  width: 29% !important;
}

&.w-21 {
  width: 21% !important;
}

&.w-60px {
  width: 60px !important;
}

&.w-80px {
  width: 80px !important;
}

&.w-140px {
  width: 140px !important;
}

&.w-170px {
  width: 170px !important;
}

&.w-325px {
  width: 325px !important;
}

&.h-100 {
  height: 100% !important;
}

&.h-111px {
  height: 111px !important;
}

&.h-150 {
  height: 150px !important;
}

&.h-320 {
  height: 320px;
}

&.h-50vh {
  height: 50vh;
}

&.h-100vh {
  height: 100vh;
}

&.h-100vh-80 {
  height: calc(100vh - 80px);
}

&.h-500 {
  height: 500px !important;
}

&.h-700 {
  height: 700px;
}

&.h-800 {
  height: 800px;
}

&.h-48 {
  height: 48px;
}

&.h-70 {
  height: 70px;
}

&.h-32 {
  height: 32px;
}

&.h-56 {
  height: 56px;
}

&.min-w-52px {
  min-width: 52px !important;
}

&.min-w-80px {
  min-width: 80px !important;
}

&.min-w-120px {
  min-width: 120px !important;
}

&.min-w-342px {
  min-width: 342px !important;
}

&.min-w-343px {
  min-width: 343px !important;
}

&.min-height-450 {
  min-height: 450px;
}

&.min-h-270px {
  min-height: 270px !important;
}

&.max-h-300px {
  max-height: 300px;
}

&.size-36 {
  height: 36px !important;
  width: 36px !important;
}

&.size-24 {
  height: 24px !important;
  width: 24px !important;
}

&.align-bottom {
  vertical-align: bottom !important;
}

&.left-38px {
  left: 38px;
}

&.width-16px {
  width: 16px;
}

&.width-38px {
  width: 38px;
}
}

.spaces_percent {
  $spaces_percent: (
    1: 1%,
    2: 2%,
    3: 3%,
    4: 4%,
    5: 5%,
    6: 6%,
    7: 7%,
    8: 8%,
    9: 9%,
    10: 10%,
    11: 11%,
    12: 12%,
    13: 13%,
    14: 14%,
    15: 15%,
    16: 16%,
    17: 17%,
    18: 18%,
    19: 19%,
    20: 20%,
    21: 21%,
    22: 22%,
    23: 23%,
    24: 24%,
    25: 25%,
    26: 26%,
    27: 27%,
    28: 28%,
    29: 29%,
    30: 30%,
    31: 31%,
    32: 32%,
    33: 33%,
    34: 34%,
    35: 35%,
    36: 36%,
    37: 37%,
    38: 38%,
    39: 39%,
    40: 40%,
    41: 41%,
    42: 42%,
    43: 43%,
    44: 44%,
    45: 45%,
    46: 46%,
    47: 47%,
    48: 48%,
    49: 49%,
    50: 50%,
    51: 51%,
    52: 52%,
    53: 53%,
    54: 54%,
    55: 55%,
    56: 56%,
    57: 57%,
    58: 58%,
    59: 59%,
    60: 60%,
    61: 61%,
    62: 62%,
    63: 63%,
    64: 64%,
    65: 65%,
    66: 66%,
    67: 67%,
    68: 68%,
    69: 69%,
    70: 70%,
    71: 71%,
    72: 72%,
    73: 73%,
    74: 74%,
    75: 75%,
    76: 76%,
    77: 77%,
    78: 78%,
    79: 79%,
    80: 80%,
    81: 81%,
    82: 82%,
    83: 83%,
    84: 84%,
    85: 85%,
    86: 86%,
    87: 87%,
    88: 88%,
    89: 89%,
    90: 90%,
    91: 91%,
    92: 92%,
    93: 93%,
    94: 94%,
    95: 95%,
    96: 96%,
    97: 97%,
    98: 98%,
    99: 99%,
    100: 100%,
  );

@each $name, $value in $spaces_percent {
  &.width-#{$name} {
    width: $value !important;
  }
}

;
}