@import "../../../../styles/variable";

.customer-border {
  border: 1px solid #b5b5b7;
  border-radius: 10px;
  min-height: 100px;
  box-shadow: 0px 1px 4px 0px #888;
}

.customer-buttom-dk {
  background-color: white !important;
  border: 1px solid #1a5e83 !important;
}

.customer-buttom-text {
  color: #1a5e83;
}

.scroll-hidden {
  overflow: hidden !important;
}

.height-footer {
  height: 45px !important;
  justify-content: end;
  align-content: center;
}

.coler-text-price {
  color: #28A754;
}

.min-w-165px {
  min-width: 165px !important;
}

.min-w-140px {
  min-width: 140px !important;
}

.border-radius-left-right-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.text-field-label-down-line-hd {
  display: block;
  align-items: center;
  margin: 0px 15px 0px 0px;
}

.min-height-200px {
  min-height: 200px !important;
}

.min-height-100px {
  min-height: 100px !important;
}

.form-ket-luan {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 225px) !important;
}

.ckeditor-custom-noi-dung .ck.ck-editor{
  width: 100%;
  
  .ck-editor__editable {
      min-height: 726px;
  }
}

.line-height-36 {
  line-height: 36px;
}

.custom-icon-text-field {
  position: absolute;
  top: 8px;
  right: 6px;
}

.btn-file {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  opacity: 0;
}