.phanHeVienPhi {
  .dsBenhNhan {
    height: calc(100% - 15px);
    display: flex;
    flex-direction: column;

    .bangDSBenhNhan {
      background-color: white !important;
      height: 100%;
      display: flex;
      flex-direction: column;

      .card-body {
        height: calc(100vh - 293px);

        table {
          tbody {
            height: 100%;
          }
        }
      }

      @media screen and (min-width: 600px) {
        .btn-group-kham-benh {
          grid-template-columns: 125px 125px;
          gap: 10px;
        }
      }

      @media screen and (min-width: 1800px) {
        .btn-group-kham-benh {
          grid-template-columns: 125px 85px 110px 80px;
          gap: 10px;
        }
      }
    }
  }

  .TTVienPhi {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 71% !important;
    background-color: white;
    flex: 9;

    .price-info {
      display: grid;
      grid-template-columns: 12fr 2fr;

      &-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 0px 10px;

        .d-flex {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

.vien-phi-loc {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu.show {
    z-index: 1001;
    width: 330px;
    top: 20px;
    left: auto;
  }
}

#form-vien-phi {
  .label-form {
    font-size: 1.05rem;
    font-weight: 500;
  }
}

.text-vien-phi {
  width: 100%;
  font-weight: 600;
  color: #006d75;

  & > span + div > div > div:first-child {
    color: #006d75;
  }
}

.h-form-vien-phi {
  height: calc(100vh - 176px);
}

.h-vien-phi-dich-vu {
  height: calc(100% - 257px);
}

.min-h-190px {
  height: 190px;
}

.min-w-576px {
  min-width: 576px;
}

.min-w-288px {
  min-width: 288px;
}

.h-calc-300px {
  height: calc(100vh - 300px);
}

.fl-4 {
  flex: 4;
}

.fl-1 {
  flex: 1;
}

.h-calc-545px {
  height: calc(100vh - 545px);
}

.h-calc-508px {
  height: calc(100vh - 508px);
}

.h-calc-400px {
  height: calc(100vh - 400px);
}

.w-173px {
  width: 173px;
}

.min-w-25-percent {
  width: 25%;
}

.thongTinVienPhi {
  .table th,
  .table td,
  .table-children th,
  .table-children td {
    min-width: 100px !important;
    max-width: 100px !important;
  }

  .header-sticky th {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
  }

  .table th:first-child,
  .table td:first-child,
  .table-children th:first-child,
  .table-children td:first-child {
    min-width: 140px !important;
    max-width: 140px !important;
  }
}

.icon-circle-check {
  position: relative;
  display: inline-block;
  max-width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #08979c;
  color: white;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  .bi-check {
    position: absolute;
    top: 54%;
    left: 46%;
    transform: translate(-50%, -50%);
    font-size: 20px;
  }
}

.background-color-blue {
  background-color: #08979c;
}

.background-color-gray {
  background-color: #ccc;
}

.mgr-8 {
  margin-right: -8px;
}

.color-count-number {
  color: #17a2b8;
}

.color-error {
  color: #dc3545;
}

.check-box-vp {
  input {
    border-color: #ccc;
    transform: translateX(10px);
  }
}

.flex-grow-18 {
  flex: 18;
}

.min-w-105px {
  min-width: 105px;
}

.w-89 {
  width: 89%;
  left: 14px;
  bottom: 13px !important;
}

.h-82 {
  height: 82%;
}

.pr-0 {
  padding-right: 0 !important;
}

.h-60 {
  height: 60%;
}

.bottom-negative-200 {
  bottom: -200px;
  border-top: 1px solid #f0f2f5;
  margin: 0 -9px;
}

.w-4 {
  width: 4%;
}

.width-74px {
  width: 74px;
}

.table,
.table-children {
  th.width-10px,
  td.width-10px {
    min-width: 10px !important;
    max-width: 10px !important;
  }

  th.width-18px,
  td.width-18px {
    min-width: 18px !important;
    max-width: 18px !important;
  }

  th.width-20px,
  td.width-20px {
    min-width: 20px !important;
    max-width: 20px !important;
  }

  th.width-25px,
  td.width-25px {
    min-width: 25px !important;
    max-width: 25px !important;
  }

  th.width-28px,
  td.width-28px {
    min-width: 28px !important;
    max-width: 28px !important;
  }

  th.width-30px,
  td.width-30px {
    min-width: 30px !important;
    max-width: 30px !important;
  }

  th.width-32px,
  td.width-32px {
    min-width: 32px !important;
    max-width: 32px !important;
  }

  th.width-35px,
  td.width-35px {
    min-width: 35px !important;
    max-width: 35px !important;
  }

  th.width-65px,
  td.width-65px {
    min-width: 65px !important;
    max-width: 65px !important;
  }
}

.invTotal.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.width-186 {
  width: 186px !important;
}