.phanHeTiepDon {
  .modelKhamBenh {
    display: flex;
    flex-direction: column;
    height: 100%;

    .bangLichSu {
      height: 170px;
    }

    .thongTinKhamBenh {
      flex: 1;
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 8px;
    }
  }

  .dsBenhNhan {
    height: calc(100% - 20px);
    .bangDSBenhNhan {
      background-color: white !important;
      height: 100%;
      padding: 8px;
      display: flex;
      flex-direction: column;

      .card-body {
        height: 100%;

        table {
          tbody {
            height: 100%;
          }
        }
      }
      @media screen and (min-width: 600px) {
        .btn-group-kham-benh {
          grid-template-columns: 125px 125px;
          gap: 10px;
        }
      }
      @media screen and (min-width: 1800px) {
        .btn-group-kham-benh {
          grid-template-columns: 125px 85px 110px 80px;
          gap: 10px;
        }
      }
    }
  }

  .tabKhamBenh {
    // background-color: white !important;
    height: 100%;
    padding: 8px;

    .tab-content {
      height: calc(100% - 26px);

      .tab {
        height: 100%;
      }
    }

    .chiDinhThuoc {
      height: 100%;

      .card-body {
        height: 100%;

        .table-responsive {
          height: 100%;

          table {
            height: 100%;

            tbody {
              height: calc(100% - 30px);
              overflow-y: auto;
            }
          }
        }
      }
    }
  }

  .tabTaiNanThuongTich {
    height: 100%;
    overflow: scroll;
  }
}
.h-calc-70px {
  height: calc(100% - 140px);
}
.text-status-blue {
  color: rgba(24, 144, 255, 1);
}
.text-status-ocean {
  color: rgba(19, 194, 194, 1);
}
.text-status-green {
  color: rgba(82, 196, 26, 1);
}
.text-status-yellow {
  color: rgba(250, 219, 20, 1);
}
.text-status-orange {
  color: rgba(250, 173, 20, 1);
}
.text-status-purple {
  color: rgba(114, 46, 209, 1);
}
.form-benh-nhan {
  height: calc(100% - 20px) !important;
  .tabs {
    margin-left: 2px;
  }
  .tab-content {
  }
  div[role="tabpanel"] {
    height: calc(100% - 10px);
  }
  .tableLichSu {
    .card-body {
      height: 100%;
    }
  }
}
.min-w-145px {
  min-width: 145px;
}
.h-calc-25px {
  height: calc(100% - 25px);
}

.tab-tai-lieu {
  .card-body {
    height: calc(100vh - 240px);
    border: 1px solid #eff2f5;
    margin-bottom: 20px;
  }
}
.max-w-245px {
  max-width: 245px;
}
.table-cdha,
.table-xn,
.table-ck {
  height: calc(100vh - 188px);
}
.h-ds-phieu {
  height: calc(100vh - 200px);
  position: sticky;
  top: 0;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.tab-kham-benh {
  overflow: auto;
  max-height: calc(100vh - 84px);
  .btn-luu {
    background: #fff;
    position: sticky;
    bottom: 0;
  }
}
.modal-thuoc,
.modal-ke-vat-tu {
  .modal-xl {
    --bs-modal-width: 1300px;
  }
  .thuoc-dialog,
  .vat-tu-dialog,
  .thanh-toan-dialog {
    .table-scroll {
      max-height: calc(100vh - 580px);
      min-height: calc(100vh - 580px);
      border: 1px solid #eff2f5;
      margin-bottom: 8px;
    }
  }
  .table-left {
    max-height: 600px;
    overflow: auto;
    .table-scroll {
      max-height: calc(100vh - 540px);
      min-height: calc(100vh - 286px);
      border: 1px solid #eff2f5;
      margin-bottom: 8px;
    }
  }
  .table-ds-right {
    .table-scroll {
      max-height: calc(100vh - 540px);
      min-height: calc(100vh - 326px);
      border: 1px solid #eff2f5;
      margin-bottom: 8px;
    }
  }
  .table-right {
    .table-scroll {
      max-height: calc(100vh - 473px);
      min-height: calc(100vh - 473px);
      border: 1px solid #eff2f5;
      margin-bottom: 8px;
    }
  }
  .dropdown-btn {
    .dropdown-menu.show {
      flex-direction: column;
      box-shadow: 2px 2px 5px #888;
    }
    .btn.btn-primary.dropdown-toggle:after {
      display: none;
    }
  }
}
.min-w-130px {
  min-width: 130px;
}
.min-w-140px {
  min-width: 140px;
}
.w-130px {
  width: 130px;
}
.min-w-145px {
  min-width: 145px;
}
.w-140px {
  width: 140px;
}
.min-w-230px {
  min-width: 230px;
}
.w-145px {
  width: 145px;
}
.w-275px {
  width: 275px;
}
.chon-ket-qua {
  right: 4px;
  top: 0px;
}
.w-calc-130px {
  width: calc(100% - 130px);
}
.h-103px {
  height: 103px !important;
}
.loai-tai-lieu {
  max-height: calc(100vh - 250px);
  overflow: auto;
  .row:hover {
    background-color: #e6fffb;
  }
}

.modal-kham-benh {
  .modal-xl {
    min-width: calc(100vw - 80px) !important;
  }
  .modal-body {
    overflow: hidden;
    padding: 0 4px !important;
  }
  .modal-content {
    max-height: none;
    overflow-y: hidden;
  }
}

.h-table-lich-su-kham {
  height: calc(100vh - 467px);
}
.modal-chinh-dinh-dich-vu {
  .modal-body {
    padding-top: 0px !important;
  }
  .form-chi-dinh-dich-vu {
    position: sticky;
    top: 57px;
    background: white;
    z-index: 1100;
    padding-top: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .w-input-ke-thuoc {
    width: 12.8%;
  }
  .p-input-ke-thuoc {
    padding-left: 1.2%;
  }
}
@media screen and (min-width: 1920px) {
  .w-input-ke-thuoc {
    width: 13.5%;
  }
  .p-input-ke-thuoc {
    padding-left: 0.8%;
  }
}

.table-ke-thuoc {
  .card-body {
    height: 100%;
  }
}

.tien-don {
  background: #f8f9fa;
}

.benh-nhan-xac-thuc {
  margin-left: -20%;
  width: 100vw;
  transform: scale(0.7);
}

.status {
  &-0 {
    &-color {
      color: #6c757d !important;
    }

    &-bg {
      background-color: #6c757d;
    }
  }
  &-1 {
    &-color {
      color: #dc3545 !important;
    }
    &-bg {
      background-color: #dc3545;
    }
  }
  &-2 {
    &-color {
      color: #ff9c07 !important;
    }
    &-bg {
      background-color: #ff9c07;
    }
  }
  &-3 {
    &-color {
      color: #17a2b8 !important;
    }
    &-bg {
      background-color: #17a2b8;
    }
  }
  &-4 {
    &-color {
      color: #28a745 !important;
    }
    &-bg {
      background-color: #28a745;
    }
  }
}

.min-w-180px {
  width: 180px !important;
}

.w-49 {
  width: 49%;
}

.fl-1 {
  flex: 1 !important;
}

.fl-4 {
  flex: 4 !important;
}

.h-calc-vh-441 {
  height: calc(100vh - 441px);
}

.color-17A2B8 {
  color: #17a2b8;
}

.color-1A5E83 {
  color: #1a5e83;
}

.border-right {
  border-right: 1px solid #dfdfdf;
}

.padding-0 {
  padding: 0 !important;
}

.price-info {
  display: grid;
  grid-template-columns: 12fr;
  &-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 10px;
    .d-flex {
      display: flex;
      justify-content: space-between;
    }
  }
}

.h-calc-vh-211 {
  height: calc(100vh - 211px) !important;
}

.price-info-thanh-toan {
  display: grid;
  grid-template-columns: 12fr;
  &-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 10px;
    .d-flex {
      display: flex;
      justify-content: space-between;
    }
  }
}

.grid-2-9 {
  display: grid;
  grid-template-columns: 2fr 9fr;
  gap: 2px;
  margin: 2px 0;
}
.grid-2-10 {
  display: grid;
  grid-template-columns: 2fr 10fr;
  gap: 2px;
  margin: 2px 0;
}
.color-DC3545 {
  color: #DC3545;
}
.grid-3_6-8_4 {
  display: grid;
  grid-template-columns: 3.6fr 8.4fr;
  gap: 2px;
  margin: 2px 0;
}

.muc-huong {
  right: 15px !important;
}

.modal-kham-ket-hop {
  .modal-xl {
    width: 800px !important;
  }
  .modal-content {
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none; 
  }
}

.grid-8-4 {
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: 2px;
  margin: 2px 0;
}

.grid-3-9 {
  display: grid;
  grid-template-columns: 3fr 9fr !important;
  gap: 2px;
  margin: 2px 0;
}

.grid-4_1-7_9 {
  display: grid;
  grid-template-columns: 4.1fr 7.9fr !important;
  gap: 2px;
  margin: 2px 0;
}

.grid-5-7 {
  display: grid;
  grid-template-columns: 5fr 7fr !important;
  gap: 2px;
  margin: 2px 0;
}

.grid-4-8 {
  display: grid;
  grid-template-columns: 4fr 8fr !important;
  gap: 2px;
  margin: 2px 0;
}

.mx-nega-24 {
  margin: 0 -24px;
  border-bottom: 1px dashed #ccc !important;
}

.dropdown-actions {
  transform: translate3d(0, 31px, 0) !important;
}

.dropdown-action-item-tooltip {
  .tooltip-inner, .list-group {
    width: max-content;
    max-width: 500px;
  }

  .tooltip-inner {
    padding: 4px 0;
  }

  .list-group {
    max-height: 250px;
    overflow: auto;

    .list-group-item {
      padding: 3px 12px;
    }
  }
}

.icon-circle {
  position: relative;
  display: inline-block; 
  max-width: 19px;
  height: 15px;
  border-radius: 50%;
  background-color: #08979c;
  color: white;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  top: 2px;
}