@import "../styles/variable";

.pe-list {
  height: 100%;

  .reception__header {
    position: relative;
    background-color: white;

    .customs-tabs {
      padding-left: 1.5rem;
    }
  }

  .receive {
    .form-tiep-nhan {
      overflow: auto;
      overflow-x: hidden;
      height: calc(100vh - 155px);
    }

    .custom-select {
      width: calc(100% - 100px);
    }

    .custom-select__control {
      width: calc(100% - 100px);
      height: 30px;
    }

    .space-row {
      margin-top: 4px;
    }

    .reception-list__container {
      // box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
      padding: 10px;
      overflow: hidden;

      .tab-content {
        height: calc(100% - 40px);
      }

      div[role="tabpanel"] {
        height: 100%;
      }
    }
  }

  .tabThanhToan {
    height: calc(100% - 20px);

    .dsHopDong {
      background-color: white !important;
      height: 100%;
      display: flex;
      flex-direction: column;

      .card-body {
        height: 100%;

        table {
          tbody {
            height: 100%;
          }
        }
      }
      @media screen and (min-width: 600px) {
        .btn-group-kham-benh {
          grid-template-columns: 125px 125px;
          gap: 10px;
        }
      }
      @media screen and (min-width: 1800px) {
        .btn-group-kham-benh {
          grid-template-columns: 125px 85px 110px 80px;
          gap: 10px;
        }
      }
    }
  }
}

.cell-first-child {
  padding-left: 10px;
}

.cell-last-child {
  padding-right: 10px;
}

.bg-collapse-custom {
  background-color: #f1f1f1 !important;
}

.w {
  &-19 {
    width: 19%;
  }

  &-28 {
    width: 28%;
  }

  &-64 {
    width: 64%;
  }

  &-67 {
    width: 66.7%;
  }

  &-68 {
    width: 68%;
  }

  &-80 {
    width: 80%;
  }
}

.tree {
  border: 1px solid #cecece;
  border-left: none;
  line-height: 21px;
  width: 25%;
  font-size: $default-font-size;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 229px);

  .quantity {
    border: 1px solid #17a2b8;
    border-radius: 5px;
    color: #fff;
    color: #17a2b8;
    display: inline-block;
    font-size: 9px;
    font-weight: 600;
    margin: 2.45px 0;
    padding: 2px;
    text-align: center;
    min-width: 16px;
    line-height: 10.5px;
  }
}

.h-70px {
  min-height: 70.5px !important;
}

.isFilterSelected {
  width: 93%;
  color: $color-text-cyan;
  background: #e6fffb;
  // border-right: #08979c 3px solid;
  line-height: 21px;
  // padding: 10px;
}

.hover-row {
  &:hover {
    width: 93%;
    color: $color-text-cyan;
    background: #e6fffb;
    // border-right: #08979c 3px solid;
    line-height: 21px;
  }

  line-height: 21px;
  width: 93%;
}

@media screen and (min-width: 1800px) {
  .w-res-filter {
    width: 19%;
  }
}

@media screen and (min-width: 1366px) {
  .sinh-hieu {
    width: 150px !important;
  }

  .huyet-ap {
    width: 66px;
  }

  .ds-tiep-nhan {
    width: 68%;
  }

  .w-res-filter {
    width: 18.7%;
  }
}

@media screen and (max-width: 1280px) {
  .sinh-hieu {
    width: 100px !important;
  }

  .huyet-ap {
    width: 41px;
  }

  .huyet-ap-2 {
    width: 50%;
  }

  .ds-tiep-nhan {
    width: 68%;
  }

  .w-res-filter {
    width: 18.4%;
  }
}

@media screen and (min-width: 1600px) {
  .sinh-hieu {
    width: 200px !important;
  }

  .huyet-ap {
    width: 91px;
  }

  .ds-tiep-nhan {
    width: 72%;
  }

  .w-res-filter {
    width: 18.9%;
  }
}

.flex-auto {
  flex: auto;
}

.flex-10-1 {
  flex: 10 1;
}

.top-n5 {
  top: -5px;
}

.h-24px {
  height: 24px;
}

.mb-4px {
  margin-bottom: 4px;
}

.ms-n4px {
  margin-left: -4px;
}

.muc-huong {
  text-align: center;
  height: 25px;
  line-height: 26px;
  margin: 0 !important;
  width: 28px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  top: 0px;
  right: 0px;
}

.customs-form-check-box {
  display: flex;
  align-items: center;

  input {
    width: 20px;
    height: 20px;
    border-radius: 3px !important;
    margin-right: 5px;
    cursor: pointer;
  }

  label {
    color: #5E6278;
    cursor: pointer;
  }
}

.mr-20 {
  margin-right: 20px;
}

.text-field-label-down-line {
  display: block;
  align-items: center;
  margin: 0px 3px 0px 3px;
}

.min-w-130px {
  min-width: 130px;
}

.min-w-110px {
  min-width: 110px;
}

.min-height-60px {
  min-height: 60px !important;
}

.search-icon {
  top: 1px !important;
}

.line-bottom {
  border-bottom: 1px solid #F0F2F5;
}

.text-error {
  color: #DC3545;
}

.text-fill {
  color: #17A2B8 !important;
}

.h-calc-416 {
  height: calc(100vh - 416px);
}

.h-calc-514 {
  height: calc(100vh - 514px);
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fw-normal {
  font-weight: normal;
}

.check-box-vp {
  input {
    border-color: #ccc;
    transform: translateX(10px);
  }
}

#kt_table_custom {
  tr {
      &:hover {
          background-color: #e6f7ff;
      }

      th {
          background-color: #3C78D8 !important;
          color: #ffffff !important;
          
          &:hover {
              background-color: #3C78D8 !important;
          }
      }
  }
}
