.search-container {
    position: relative;
}

.table-search-input {
    outline: none;
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd !important;
    border-radius: 8px;
}

.icon-search {
    position: absolute;
    right: 10px;
    top: 6px;
    cursor: pointer;

    i {
        font-size: 18px;
    }
}