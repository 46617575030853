.datLichContainer {
    height: 90vh;
    overflow-y: auto;

    .search-icon,
    .muc-huong {
        text-align: center;
        background: rgba(217, 217, 217, 1);
        height: 25px;
        line-height: 26px;
        margin: 0 !important;
        width: 28px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        top: 0px;
        right: 0px;
        border: 1px solid #a9a9a9 !important;
    }

    .text-info {
        color: #1A5E83 !important;
    }

    .btn-createNew,
    .btn-save {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FF9700 !important;
        color: white !important;
        font-weight: 400;
        border-radius: 4px;
        padding: 4px 8px !important;
        line-height: 21px;
        width: 195px;
        text-align: center;

        &:hover {
            background-color: #17a2b8 !important;
        }
    }

    .btn-save {
        background-color: #00A94E !important;
    }
}