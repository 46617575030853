.phanHeKhoDuoc {
    height: 100%;

    .tab-content {
        height: calc(100% - 26px);

        .tab {
            height: 100%;
        }
    }

    .soDo {
        .customs-tabs {
            background-color: white !important;
        }
    }

    .danh-muc-mau {
        .dsMau-table {
            .table-scroll {
                max-height: calc(100vh - 260px);
            }
        }
    }

    .dsKho {
        .dsKho-table {
            .table-scroll {
                max-height: calc(100vh - 260px);
            }
        }
    }
    .dsThuoc {
        .dsThuoc-table {
            .table-scroll {
                max-height: calc(100vh - 260px);
            }
        }
    }
}

.modalContentXuatKho {
    width: 75%;
    height: auto !important;
    margin: auto;
}
.scroll-modal {
    max-height: calc(100vh - 160px);
    overflow-y: scroll;
}

.kho-noi-tru-container {

    .kho-noi-tru-menu {
        background: white;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
    }

    .kho-noi-tru-content {
        gap: 10px;
        display: flex;

        .row-tree {
            height: 26px;
        }

    }
}