.Table {
    width: 100%;
    margin-top: 15px;
}

.headerRow,
.evenRow,
.oddRow {
    border-bottom: 1px solid #e0e0e0;
}

.oddRow {
    background-color: #fafafa;
}

.headerColumn {
    text-transform: none;
    text-align: center;
}

.exampleColumn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.checkboxLabel {
    margin-left: .5rem;
}

.checkboxLabel:first-of-type {
    margin-left: 0;
}

.noRows {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    color: #bdbdbd;
}

.bg-pri {
    background-color: #3C78D8;
}

.ReactVirtualized__Table__headerRow {
    background-color: #3C78D8;
}

.ReactVirtualized__Table__headerColumn {
    margin-right: 0px;
    padding-top: 5px;
    border-right: 1px solid #DEE2E6;
}

.ReactVirtualized__Table__headerColumn:last-child, 
.ReactVirtualized__Table__rowColumn:last-child {
    border-right: none;
}

.ReactVirtualized__Table__rowColumn {
    padding: 5px !important;
    margin-right: 0px;
    border-right: 1px solid #ced4da;
    color: #000000 !important;
    font-weight: 400;
}

.ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 0px;
}

.ReactVirtualized__Table__headerColumn:first-of-type {
    margin-left: 0px;
}

.checkBox {
    margin-top: 5px;
}

.radio {
    margin-top: 5px;
    margin-left: 5px;
}

.ReactVirtualized__Table__row {
    cursor: pointer;
    
    &:hover {
        background-color: var(--kt-gray-100);
    }
}