.collapse-header {
    &-0 {
        button {
            padding: 6px 5px !important;
        }

        .collapse-cell {
            padding-left: 15px !important;
        }
    }

    &-1 {
        button {
            padding: 6px 15px !important;
        }

        .collapse-cell {
            padding-left: 35px !important;
        }
    }

    &-2 {
        button {
            padding: 6px 35px !important;
        }

        .collapse-cell {
            padding-left: 55px !important;
        }
    }

    &-3 {
        button {
            padding: 6px 55px !important;
        }

        .collapse-cell {
            padding-left: 75px !important;
        }
    }
}

.collapse-row {
    button {
        padding: 0 !important;
        &>table {
            margin-left: -20px !important;
        }
        &::after {
            display: inline-block;
            margin-left: 6px !important;
        }
    }
    .collapse-cell {
        padding-left: 31px !important;
    }
}