.collapse-custom {
    width: 100%;
    height: 40px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-top: none;

    .collapse-icon {
        position: absolute;
        right: 20px;

        i {
            font-size: 15px;
        }
    }
}

.table-collapse {
    .table-collapse-body {
        text-align: center;

        .table-collapse-list {
            .table-collapse-item {
                border: 1px solid #ddd !important;

                &:first-child {
                    width: 10%;
                }

                &:nth-child(2) {
                    text-align: left;
                }

                padding: 10px;
                width: 22%;
            }
        }
    }
}

.collapse-header {
    position: sticky;
    top: 0;
    z-index: 10;
}

.collapse-body {
    overflow-y: auto;
    height: 420px;
}

.table-collapse-scroll::-webkit-scrollbar {
    width: 0;
}