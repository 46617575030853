.custom-icon-button {
  padding: 3px 6px;
  border-radius: 12px;
  &:hover {
    background-color: rgba($color: #ffffff, $alpha: 0.5);
  }
}

.delete {
  i {
    color: red;
    font-size: 1.1rem;
    cursor: pointer;
  }
}

.edit {
  i {
    color: #009ef7;
    font-size: 1.1rem;
    cursor: pointer;
  }
}

.button-tooltip {
  .tooltip-inner {
    padding: 4px 6px;
    font-size: 0.88rem;
    font-weight: 500;
    background-color: #999999;
    color: #ffffff;
  }
}