@import "../../../../styles/variable";

.customer-border {
  border: 1px solid #b5b5b7;
  border-radius: 10px;
  min-height: 100px;
  box-shadow: 0px 1px 4px 0px #888;
}

.customer-buttom-dk {
  background-color: white !important;
  border: 1px solid #1a5e83 !important;
}

.customer-buttom-text {
  color: #1a5e83;
}

.modelKhamBenh {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 84px);
}

.btn-luu {
  background: #fff;
  position: sticky;
  bottom: 0;
}

.min-height-90px {
  min-height: 90px !important;
}
.customer-icon-dot {
  align-content: center;
  font-size: 0.4rem !important;
  color: #1a5e83 !important;
  margin-right: 10px;
}
.h-bang-ds-bn {
  height: calc(100vh - 195px);
}
.text-title-kls{
  color: #0D47A1;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  &-icon{
    font-weight: bold;
    font-size: 20px;
  }
}

.columnsContent {
  display: flex;
  flex-wrap: nowrap;
}

.lable-blood-pressure {
  margin: 10px 0 0 5px;
  width: 75px;
  min-width: 75px;
  
  span {
    width: 75px !important;
    min-width: 75px !important;
  }
}

.blood-pressure-icon {
  width: 10px;
  min-width: 10px;
  margin: 10px 0 0 10px;

  span {
    width: 10px !important;
    min-width: 10px !important;
  }
}

.blood-pressure-input-1 {
  width: calc(37% - 57px);
}

.blood-pressure-input-2 {
  width: calc(37% - 54px);
}

.blood-pressure-unit {
  min-width: 25% !important;
  width: 25%;
  margin: 10px 0 0 20px;

  span {
    min-width: 100% !important;
    width: 100%;
  }
}